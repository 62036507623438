import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './MoviePlayer.css';
import MovieList from './MovieList';

// Function to shuffle the array and get the first `count` items
const getRandomMovies = (movies, count) => {
  const shuffled = movies.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const MoviePlayer = ({ movies }) => {
  const { title } = useParams();
  const navigate = useNavigate();
  const [clickCount, setClickCount] = useState(0); // Track the number of clicks on the download button

  const handleSelectMovie = (movie) => {
    const encodedTitle = encodeURIComponent(movie.title);
    navigate(`/movie/${encodedTitle}`);
  };

  useEffect(() => {
    // Reset click count when the title (selected movie) changes
    setClickCount(0);
  }, [title]);

  // Find the movie based on the URL parameter
  let selectedMovie = movies.find(movie => movie.title === decodeURIComponent(title));
  if(!selectedMovie){
    selectedMovie = movies.find(movie => movie.title.toLowerCase() === decodeURIComponent(title).toLowerCase().replace(/-/g, ' '));
  }

  if (!selectedMovie) return <div>Loading...</div>;

  // Filter out the currently selected movie from the recommendations
  const recommendedMovies = movies.filter(movie => movie.title !== selectedMovie.title);

  // Get 20 random movies from the recommendations
  const randomMovies = getRandomMovies(recommendedMovies, 20);

  const handleDownloadClick = () => {
    if (clickCount < 3) {
      // First or second click: redirect to ad URL
      window.open('https://eweconciliate.com/kxmzskh900?key=a6531be8235dcc200df4375f01726883', '_blank');
      setClickCount(prevCount => prevCount + 1); // Increment the click count
    } else {
      // After two clicks: redirect to the actual download URL
      window.open(selectedMovie.downloadSrc, '_blank');
    }
  };

  return (
    <div className="movie-player">
      <h4>{selectedMovie.title}</h4>
      <iframe src={selectedMovie.iframeSrc} title={selectedMovie.title} allowFullScreen></iframe>

      <div className="movie-player-buttons">
        <button className="movie-button-download" onClick={handleDownloadClick}>
          Download
        </button>
        {clickCount < 3 && (
          <span className="click-instructions">
            Click {3 - clickCount} more time{clickCount === 2 ? '' : 's'} to get the final download link.
          </span>
        )}
      </div>

      <h3 className="recommended-videos-title">Recommended Videos</h3>
      <MovieList movies={randomMovies} onSelectMovie={handleSelectMovie} />
    </div>
  );
};

export default MoviePlayer;
